import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { InvoicesAppContext } from '../../context';
import { checkPromotions } from '../../../../hooks/checkPromotions';

export const PackagePaymentView = ({
  name,
  promotions,
  expired,
  date,
  option,
  setOption,
  setDate,
  multiplyPackageData,
  todayDateOption,
  walletAmount,
  adsCount,
  expireDateOption,
  invoicePrice,
  handlePaymentClick,
  isPackagePaymentBlocked,
  showPromotions,
  discount,
  showPaidDiscount,
  dateLoader,
  impressionsCount,
  rawPricePerPeriod,
  discountsPercents,
  displayDiscounts,
  rawPricePerPeriodKeys,
  promoPricePerPeriod,
  promoPriceDiscounts,
  promoPriceDisplayDiscounts
}) => {
  const { t } = useTranslation();
  const {
    isMobileSite
  } = useContext(InvoicesAppContext);
  const renderPromotions = checkPromotions(promotions, showPromotions);

  const packageDescription = classNames(
    'package-payment__item_description',
    name?.toLowerCase() === 'platinum+'
      ? 'platinum-plus' : name?.toLowerCase()
  );

  const packageInputs = (input, days) => classNames(
    { active: input === days }
  );

  const showDiscountsLabels = days => (displayDiscounts && displayDiscounts[days])
    || (promoPriceDisplayDiscounts && promoPriceDisplayDiscounts[days]);

  const packageDiscounts = param => classNames(
    'package-info',
    { 'not-include': !param }
  );

  return (
    <>
      {!isMobileSite && (
        <>
          <p className="package-payment__title">{t('mobilePaymentPackTitle')}</p>
          <p className="package-payment__item_title">{t('payment.title')}</p>
        </>
      )}
      <div className="package-payment__items_container">
        <div className="package-payment__items">
          {isMobileSite && <p className="package-payment__title">{t('payment.title')}</p>}
          <div className="package-payment__item">
            <div className={packageDescription}>
              <p>{name}</p>
              <div className="package-info__container">
                <div className="package-info__left">
                  <div className="package-info">
                    {adsCount}
                    {t('packages.count', { count: adsCount })}
                  </div>
                  <div className="package-info">
                    {t('packages.services_price', { services_price: walletAmount })}
                  </div>
                </div>
                <div className="package-info__right">
                  {impressionsCount ? (
                    <div className="package-info">
                      {t('packages.impressionCount', { count: impressionsCount })}
                    </div>
                  ) : renderPromotions}
                  {showPaidDiscount && (
                    <div className={packageDiscounts(discount)}>
                      {discount ? t('packages.discountServices', { discount })
                        : t('packages.withoutDiscountServices')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="package-payment__item">
            <p className="package-payment__item_title">{t('packages.period')}</p>
            <div className="package-payment__inputs">
              {rawPricePerPeriodKeys?.map((days, index) => (
                <label
                  key={`value${days}`}
                  htmlFor={`value${days}`}
                  className={packageInputs(Number(option), Number(days))}
                >
                  <input
                    onClick={() => {
                      setOption(Number(days));
                      multiplyPackageData(index + 1);
                    }}
                    id={`value${days}`}
                    name="payment-option"
                    type="radio"
                    value={`value${days}`}
                  />
                  <span>
                    {t('payment.period_days', { days })}
                  </span>
                  {showDiscountsLabels(days) && (
                    <span className="package-payment__item_discount">
                      %
                    </span>
                  )}
                </label>
              ))}
            </div>
          </div>
          <div className="package-payment__item">
            <p className="package-payment__item_title">{t('payment.activate')}</p>
            {dateLoader ? (
              <div className="invoices_preloader" style={{ margin: 'auto' }}>Loading ...</div>
            ) : (
              <div className="package-payment__inputs">
                <label
                  htmlFor="today"
                  className={packageInputs(date, todayDateOption)}
                >
                  <input
                    onClick={() => setDate(todayDateOption)}
                    id="today"
                    name="payment-day"
                    type="radio"
                    value={t('payment.today')}
                  />
                  <span>{t('payment.today')}</span>
                </label>

                { expired && (
                <label
                  htmlFor="date"
                  className={packageInputs(date, expireDateOption)}
                >
                  <input
                    onClick={() => setDate(expireDateOption)}
                    id="date"
                    name="payment-day"
                    type="radio"
                    value={expired}
                    defaultChecked
                  />
                  <span>
                    {expireDateOption }
                  </span>
                </label>
                )}
              </div>
            )}
          </div>
          {!isMobileSite && (
            <p className="package-payment__confirm-rules">
              <span>{t('modal.confirmRules1')}</span>
              <a href="/pages/terms-and-conditions">{t('modal.confirmRules2')}</a>
              <span>{t('modal.confirmRules4')}</span>
              <a href="/pages/proposal">{t('modal.confirmRules5')}</a>
              <span>{t('modal.confirmRules6')}</span>
            </p>
          )}
        </div>
        <div className="package-payment__info">
          <div className="package-payment__info_result">
            { isMobileSite && (
              <p>{t('payment.finish_title')}</p>
            ) }
            <div className="package-payment__info_result-content">
              <div className="package-payment__info_titles">
                <div>{t('payment.title')}</div>
                <div>{t('payment.period')}</div>
                <div>{t('payment.date_of_apply')}</div>
              </div>
              <div className="package-payment__info_values">
                <div>{name}</div>
                <div>{t('payment.period_days', { days: option })}</div>
                <div>{ date }</div>
              </div>
            </div>
          </div>

          <div className="package-payment__info_result package-payment__info_result__prices">
            <div className={classNames({ 'package-payment__info_container package-payment__info_container__prices': isMobileSite })}>
              {promoPriceDiscounts && promoPriceDisplayDiscounts[option] && (
                <div className="package-payment__info_prices">
                  <div className="package-payment__info_prices_block">
                    <div>{t('payment.promo')}</div>
                    <div>
                      { promoPriceDisplayDiscounts[option] && (
                        <div className="package-payment__info_discount">
                          <div>
                            <span>{`${rawPricePerPeriod[option]} AZN`}</span>
                            <span>{`-${Number(promoPriceDiscounts[option])?.toFixed(0)}%`}</span>
                          </div>
                          <div className="package-payment__info_discount__final">{`${promoPricePerPeriod[option]} AZN`}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {displayDiscounts && displayDiscounts[option] && (
                <>
                  {(!promoPriceDisplayDiscounts || !promoPriceDisplayDiscounts[option]) && (
                    <div className="package-payment__info_prices">
                      <div className="package-payment__info_prices_block">
                        <div>{t('payment.price')}</div>
                        <div className="package-payment__info_discount__prices">
                          { displayDiscounts[Number(option)] && (
                            <span>{`${promoPricePerPeriod[option]} AZN`}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="package-payment__info_prices package-payment__info_prices__promotion">
                    <div className="package-payment__info_prices_block">
                      <div>{t('payment.promotion')}</div>
                      <div className="package-payment__info_promotion">
                        <span>{`-${promoPricePerPeriod[option] - invoicePrice} AZN `}</span>
                        <span>{`-${Number(discountsPercents[option])?.toFixed(0)}%`}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="package-payment__info_prices package-payment__info_prices__final">
                {
                  ((promoPriceDisplayDiscounts && promoPriceDisplayDiscounts[option])
                    || (displayDiscounts && displayDiscounts[option]))
                    && <div className="package-payment__info_prices__delimeter" />
                }
                <div className="package-payment__info_prices_block package-payment__info_prices_block__final">
                  <div>{t('payment.finish')}</div>
                  <div>{`${invoicePrice} AZN`}</div>
                </div>
              </div>
            </div>
          </div>

          <footer className="package-payment__info_footer">
            <button
              className="invoices_balance-pay"
              value={t('pay')}
              type="button"
              disabled={isPackagePaymentBlocked}
              onClick={handlePaymentClick}
            >
              {t('pay')}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};
