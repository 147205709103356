import { arrayOf, number, object } from 'prop-types';
import { format, parseISO } from 'date-fns';
import { paidStatuses, RoutePath } from '../constants';
import { TimeFormat } from '../../../types';
import { getToken, convertArrayToObject } from '../../../helpers';

export const PaidStatusesMap = convertArrayToObject(paidStatuses);

export const filterInvoices = (
  filterKeyword, allInvoices = []
) => (filterKeyword === PaidStatusesMap.all
  ? allInvoices
  : allInvoices.filter(inv => inv.status === filterKeyword));

filterInvoices.propTypes = {
  tab: object,
  allInvoices: arrayOf(number)
};

export const getStatusFormatDate = (
  status, status_datetime
) => ((status === PaidStatusesMap.unpaid) && status_datetime
  ? null
  : format(parseISO(status_datetime), TimeFormat.fullTime));

export const getPaidStatusDispatcher = t => ({
  paid: t(PaidStatusesMap.paid),
  unpaid: t(PaidStatusesMap.unpaid),
  canceled: t(PaidStatusesMap.canceled),
  overdue: t(PaidStatusesMap.overdue),
  revoked: t(PaidStatusesMap.revoked)
});

export const openNewPaymentWindow = (data, openedWin) => {
  const openRequestedSingleTab = url => {
    setTimeout(() => {
      openedWin.location = url;
      openedWin.focus();
    }, 500);
  };

  if (!data.url && data.status === 'success') {
    window.location = RoutePath.packages;
  } else {
    return !!data?.url
      && setTimeout(() => {
        openRequestedSingleTab(data.url);
      });
  }
};

export const paymentData = selectedOption => ({
  headers: {
    'Content-Type': 'application/json',
    'X-Csrf-Token': getToken()
  },
  body: JSON.stringify({
    payment_method: selectedOption,
    service_id: 'pay_invoice'
  })
});
